import {
	INBOX_LIST,
	INBOX_LIST_SUCCESS,
} from '../constants/Inbox';

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  list:[],
}

const inboxReducer = (state = initState, action) => {
	switch (action.type) {
		case INBOX_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				redirect: '/',
				token: action.data
			}
		
		default:
			return state;
	}
}

export default inboxReducer