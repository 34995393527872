import React from "react";

export const ReplySVG = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <path
      d="M537.016,909.264c-7.034,0-14.006-2.372-19.671-6.97L18.508,496.986C11.232,491.055,7,482.161,7,472.781
			c0-9.374,4.232-18.267,11.508-24.204L517.345,43.272c9.318-7.551,22.258-9.104,33.064-3.959
			c10.871,5.175,17.785,16.135,17.785,28.162v219.277c243.388,16.107,436.483,219.246,436.483,466.625v62.353
			c0,12.18-7.097,23.235-18.147,28.314c-11.054,5.119-24.054,3.292-33.311-4.626l-52.55-45.027
			c-93.318-79.986-210.359-126.841-332.476-133.66v217.36c0,12.022-6.914,22.986-17.785,28.158
			C546.146,908.262,541.58,909.268,537.016,909.264L537.016,909.264z M125.715,472.781L506.65,782.309V614.776
			c0-15.697,12.702-28.401,28.399-28.401c134.946,0,265.707,48.367,368.18,136.193l0.972,0.834
			c-2.664-201.286-167.231-364.213-369.148-364.213c-15.699,0-28.4-12.704-28.4-28.399V163.258
			C506.65,163.258,125.715,472.781,125.715,472.781L125.715,472.781z"
    />
  </svg>
);

export const GoogleSVG = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <g>
      <path
        fill="#59C36A"
        d="M827.301,875.968c-84.521,71.388-194.169,116.693-313.528,116.693c-177.609,0-333.52-97.848-418.041-240.62
				l31.646-145.858l140.255-26.039c32.553,105.078,130.779,182.178,246.141,182.178c55.964,0,107.937-17.703,150.767-49.112
				l134.777,20.558L827.301,875.968z"
      />
      <path
        fill="#00A66C"
        d="M827.301,875.968l-27.984-142.201l-134.777-20.558c-42.83,31.409-94.803,49.112-150.767,49.112v230.34
				C633.132,992.661,742.779,947.355,827.301,875.968z"
      />
      <g id="Connected_Home_1_">
        <g>
          <g>
            <g>
              <path
                fill="#FFDA2D"
                d="M256.781,505.331c0,26.267,3.998,51.396,10.851,74.813l-171.9,171.897
								c-42.83-71.957-69.29-156.48-69.29-246.71c0-90.233,26.46-174.754,69.29-246.711l137.965,23.743l33.936,148.154
								C260.779,453.932,256.781,479.06,256.781,505.331L256.781,505.331z"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        fill="#4086F4"
        d="M1001.103,505.331c0,148.48-68.719,281.547-173.802,370.637L664.539,713.209
				c33.121-23.988,61.107-55.971,79.384-93.66h-230.15c-15.993,0-28.556-12.567-28.556-28.554V419.666
				c0-15.99,12.563-28.554,28.556-28.554h450.78c13.707,0,25.698,9.708,27.983,23.412
				C998.247,444.225,1001.103,475.063,1001.103,505.331L1001.103,505.331z"
      />
      <path
        fill="#4175DF"
        d="M743.924,619.549c-18.275,37.689-46.264,69.672-79.382,93.66l162.759,162.759
				c105.083-89.09,173.802-222.153,173.802-370.637c0-30.269-2.855-61.106-8.567-90.807c-2.284-13.704-14.278-23.412-27.984-23.412
				H513.772v228.437H743.924z"
      />
      <path
        fill="#FF641A"
        d="M835.297,154.107c0.571,7.996-2.855,15.422-7.996,21.131L705.086,296.881
				c-9.704,10.278-25.694,11.421-37.118,2.855c-45.119-33.693-98.231-51.396-154.195-51.396
				c-115.361,0-213.588,77.095-246.141,182.178L95.731,258.62C180.253,115.848,336.163,18,513.772,18
				c113.647,0,224.439,41.88,311.244,114.978C831.298,138.119,834.723,146.112,835.297,154.107L835.297,154.107z"
      />
      <path
        fill="#F03800"
        d="M667.966,299.736c11.422,8.567,27.411,7.423,37.119-2.855l122.214-121.643
				c5.143-5.709,8.569-13.133,7.996-21.131c-0.575-7.997-3.999-15.988-10.279-21.13C738.212,59.88,627.42,18,513.772,18v230.34
				C569.736,248.34,622.849,266.043,667.966,299.736z"
      />
    </g>
  </svg>
);

export const FacebookSVG = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <g>
      <path
        fill="#4A7AFF"
        d="M1015.363,506.525c0,279.749-226.775,506.524-506.525,506.524c-279.749,0-506.524-226.775-506.524-506.524
	C2.313,226.775,229.089,0,508.838,0C788.588,0,1015.363,226.775,1015.363,506.525z"
      />
      <path
        fill="#FFFFFF"
        d="M663.688,149.015v114.697c0,0-131.686-19.113-131.686,65.843v84.957h118.941L636.08,544.072H532.002v337.709
	H400.316V544.072l-112.572-2.126V414.512H398.19V316.81c0,0-7.288-145.343,135.938-172.038
	C593.602,133.68,663.688,149.015,663.688,149.015z"
      />
      <path
        fill="#DCE1EB"
        d="M663.688,263.712V149.015c0,0-70.086-15.335-129.56-4.243c-9.291,1.73-17.932,3.973-25.993,6.623v730.387
	h23.867V544.072h104.07l14.871-129.561H532.002c0,0,0,0,0-84.957C532.002,244.599,663.688,263.712,663.688,263.712z"
      />
    </g>
  </svg>
);

export const TwitterSVG = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <path
      fill="#03A9F4"
      d="M1006.701,163.276c-6.71-7.344-17.513-9.249-26.334-4.649c-9.059,4.107-18.431,7.508-28.042,10.136
      c14.983-19.171,26.285-40.938,33.356-64.208c3.113-11.713-3.859-23.729-15.573-26.834c-6.251-1.668-12.92-0.484-18.218,3.219
      c-33.365,18.063-68.824,31.936-105.59,41.3c-75.666-71.428-190.939-80.332-276.685-21.372
      c-66.047,44.667-102.419,121.845-94.796,201.207c-143.168-12.197-274.902-83.141-363.884-195.983
      c-4.574-5.527-11.491-8.575-18.653-8.206c-7.262,0.436-13.833,4.443-17.545,10.703c-30.202,49.365-38.951,108.916-24.231,164.878
      c7.548,28.757,20.485,55.813,38.136,79.74c-7.942-3.894-15.434-8.682-22.292-14.268c-9.413-7.632-23.229-6.193-30.86,3.22
      c-3.17,3.909-4.904,8.788-4.912,13.815c1.314,81.186,45.891,155.489,116.917,194.842c-9.586-1.175-19.04-3.204-28.264-6.054
      c-11.607-3.491-23.846,3.088-27.338,14.687c-1.519,5.06-1.166,10.505,1.004,15.326c30.826,69.055,92.447,119.488,166.24,136.039
      c-70.303,39.665-151.25,56.33-231.5,47.657c-10.399-1.323-20.255,5.002-23.385,15.015c-3.228,9.931,0.993,20.765,10.095,25.891
      c98.254,58.384,210.029,90.139,324.3,92.16c109.632-0.403,216.707-33.152,307.808-94.132
      C820.37,681.983,928.769,474.763,913.672,289.359c37.184-27.533,69.439-61.153,95.403-99.446
      C1014.406,181.519,1013.437,170.586,1006.701,163.276z"
    />
  </svg>
);
export const PracticeSVG = () => (
  <span style={{ fontSize: "16px", lineHeight: "40px" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 122.88 119.78"
      width="1.3em"
      height="1.3em"
      style={{ marginRight: "28px", marginTop: "8px" }}
    >
      <title>realtime</title>
      <path
        d="M63,7.81A3.91,3.91,0,1,1,63,0,59.63,59.63,0,0,1,93.56,8.38l-.39.33c-1.74,1.57-3.57,3.4-5.42,5.35A52,52,0,0,0,63,7.81ZM47.94,47.25l10.36-.14.77.2A38.58,38.58,0,0,1,65,51.45a41.08,41.08,0,0,1,3.81,3.67A240.17,240.17,0,0,1,83.9,34.31C88.49,28.78,95,21,100.09,16.45l1-.39h11.31l-2.28,2.53A293.89,293.89,0,0,0,89.38,45.44c-5.75,8.31-14.15,21.18-18.72,30l-1.42,2.74-1.31-2.79A67.83,67.83,0,0,0,59.2,61.23,57.29,57.29,0,0,0,47.09,50l.85-2.77ZM40.13,31.7a3.89,3.89,0,1,1,6.74-3.87l1.75,3a3.89,3.89,0,1,1-6.75,3.88l-1.74-3ZM28.76,46.92a3.9,3.9,0,0,1,3.91-6.75l3,1.75a3.9,3.9,0,0,1-3.91,6.75l-3-1.75ZM34.25,82.8a3.89,3.89,0,0,1-3.88-6.75l3-1.75a3.89,3.89,0,0,1,3.88,6.75l-3,1.75Zm-8.34-19a3.89,3.89,0,1,1,0-7.78H29.4a3.89,3.89,0,1,1,0,7.78Zm39.77,32a3.89,3.89,0,0,1-7.78,0v-3.5a3.89,3.89,0,0,1,7.78,0v3.5ZM57.9,24a3.89,3.89,0,0,1,7.78,0V27.5a3.89,3.89,0,1,1-7.78,0V24ZM47.22,92.91A3.89,3.89,0,1,1,40.48,89l1.74-3A3.9,3.9,0,0,1,49,89.89l-1.75,3ZM84.7,89A3.89,3.89,0,0,1,78,92.9l-1.75-3A3.89,3.89,0,0,1,83,86l1.75,3Zm10.11-13a3.9,3.9,0,0,1-3.9,6.75l-3-1.75a3.9,3.9,0,0,1,3.91-6.75l3,1.75Zm5.26-20a3.89,3.89,0,1,1,0,7.78H96.35a3.89,3.89,0,0,1,0-7.78ZM16.5,17a5.17,5.17,0,0,1,1.57-.32,5.05,5.05,0,0,1,5.65,3.53L27.87,33.7a5.07,5.07,0,1,1-9.7,2.94l-.66-2.15a52.08,52.08,0,0,0,82.3,62.22,52.09,52.09,0,0,0,9.43-60.78c1.74-2.24,3.52-4.45,5.29-6.55A59.89,59.89,0,1,1,3.1,59.89a59.82,59.82,0,0,1,8.07-30L6.89,31.51A5.08,5.08,0,1,1,3.28,22L16.5,17Z"
        fill="#00c067"
      />
    </svg>
  </span>
);

export const AssessmentSVG = () => (
  <span style={{ fontSize: "16px", lineHeight: "40px" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 122.88 119.78"
      width="1.3em"
      height="1.3em"
      style={{ marginRight: "28px", marginTop: "8px" }}
    >
      <g>
        <path
          d="M64.44,61.11c1.79,0,3.12-1.45,3.12-3.12c0-1.78-1.45-3.12-3.12-3.12H24.75c-1.78,0-3.12,1.45-3.12,3.12 c0,1.78,1.45,3.12,3.12,3.12H64.44L64.44,61.11L64.44,61.11L64.44,61.11z M77.45,19.73l18.1-19.14c0.69-0.58,1.39-0.81,2.2-0.35 l14.56,14.1c0.58,0.69,0.69,1.5-0.12,2.31L93.75,36.14L77.45,19.73L77.45,19.73L77.45,19.73L77.45,19.73z M87.74,42.27l-18.66,3.86 l2.36-20.28L87.74,42.27L87.74,42.27z M19.14,13.09h41.73l-3.05,6.45H19.14c-3.48,0-6.65,1.43-8.96,3.73s-3.73,5.46-3.73,8.96 v45.74c0,3.48,1.43,6.66,3.73,8.96c2.3,2.3,5.47,3.73,8.96,3.73h3.72v0.01l0.21,0.01c1.77,0.12,3.12,1.66,2.99,3.43l-1.26,18.1 L48.78,97.7c0.58-0.58,1.38-0.93,2.27-0.93h37.32c3.48,0,6.65-1.42,8.96-3.73c2.3-2.3,3.73-5.48,3.73-8.96V50.45h6.68v42.69 c0.35,9.63-3.58,15.04-19.43,15.7l-32.25-0.74l-32.73,13.87l-0.16,0.13c-1.35,1.16-3.38,1-4.54-0.36c-0.57-0.67-0.82-1.49-0.77-2.3 l1.55-22.34h-0.26c-5.26,0-10.05-2.15-13.52-5.62C2.15,88.03,0,83.24,0,77.98V32.23c0-5.26,2.15-10.05,5.62-13.52 C9.08,15.24,13.87,13.09,19.14,13.09L19.14,13.09L19.14,13.09z M79.69,78.42c1.79,0,3.12-1.45,3.12-3.12 c0-1.79-1.45-3.12-3.12-3.12H24.75c-1.78,0-3.12,1.45-3.12,3.12c0,1.78,1.45,3.12,3.12,3.12H79.69L79.69,78.42L79.69,78.42 L79.69,78.42z M50.39,43.81c1.78,0,3.12-1.45,3.12-3.12c0-1.67-1.45-3.12-3.12-3.12H24.75c-1.78,0-3.12,1.45-3.12,3.12 c0,1.78,1.45,3.12,3.12,3.12H50.39L50.39,43.81L50.39,43.81L50.39,43.81z"
          fill="#00c067"
        />
      </g>
    </svg>
  </span>
);

export const ImageSvg = () => (
  <svg viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
    <g>
      <g>
        <path
          fill="#E5E1E5"
          d="M163.033,1011.756L2.746,191.353c-4.863-24.879,11.365-48.991,36.245-53.852l124.042-24.234l194.642-42.82
          l279.663,941.308H163.033V1011.756z"
        />
      </g>
      <path
        fill="#99E6FC"
        d="M163.033,680.979L68.355,196.393l94.678-18.501l143.802-23.615l62.994,584.599L163.033,680.979z"
      />
      <g id="XMLID_159_">
        <g>
          <path
            fill="#F9F7F8"
            d="M1014.357,64.9v957.461c0,25.351-20.549,45.899-45.899,45.899H208.93
            c-25.351,0-45.901-20.549-45.901-45.899V64.9c0-25.35,20.551-45.9,45.901-45.9h759.528C993.809,19,1014.357,39.551,1014.357,64.9
            z"
          />
        </g>
        <path
          fill="#EFEDEF"
          d="M574.473,971.206c-90.848,0-164.495-73.646-164.495-164.493V19H208.93
          c-25.351,0-45.901,20.551-45.901,45.9v957.461c0,25.351,20.551,45.899,45.901,45.899h759.528
          c25.351,0,45.899-20.549,45.899-45.899v-51.155H574.473z"
        />
        <path
          fill="#FEC165"
          d="M950.933,737.554V234.861c0-7.122-5.774-12.896-12.897-12.896H239.354c-7.12,0-12.896,5.774-12.896,12.896
          v502.692H950.933z"
        />
        <path
          fill="#FDB441"
          d="M409.978,221.965H239.354c-7.12,0-12.896,5.774-12.896,12.896v502.692h183.52V221.965z"
        />
        <circle fill="#FEE903" cx="588.693" cy="600.309" r="246.948" />
        <path
          fill="#F4D902"
          d="M409.978,770.729V429.889c-42.274,44.316-68.229,104.339-68.229,170.419
          C341.748,666.391,367.703,726.41,409.978,770.729z"
        />
        <g>
          <path
            fill="#99E6FC"
            d="M902.813,668.316c-57.591-25.393-122.604-28.267-182.203-8.034l-51.163,17.336
            c-52.369,17.759-109.135,17.759-161.505,0l-51.163-17.336c-59.602-20.232-124.611-17.358-182.182,8.034l-48.142,21.226v105.269
            l80.12,33.354h599.658l44.699-33.354V689.542L902.813,668.316z"
          />
          <path
            fill="#62DBFB"
            d="M409.978,828.165V649.264c-45.72-6.239-92.605,0.184-135.379,19.053l-48.141,21.226v105.269
            l80.119,33.354H409.978z"
          />
          <path
            fill="#62DBFB"
            d="M950.933,794.811v61.709c0,5.452-4.424,9.878-9.879,9.878H236.332c-5.453,0-9.877-4.426-9.877-9.878
            v-61.709l48.142-21.229c57.57-25.39,122.58-28.268,182.182-8.055l51.163,17.358c52.37,17.759,109.136,17.759,161.505,0
            l51.163-17.358c59.6-20.213,124.612-17.335,182.203,8.055L950.933,794.811z"
          />
          <path
            fill="#01D0FB"
            d="M236.332,866.397h184.86c-7.214-18.51-11.215-38.625-11.215-59.685v-52.188
            c-45.72-6.231-92.605,0.192-135.379,19.061l-48.141,21.226v61.71c-0.003,5.451,4.421,9.875,9.874,9.875V866.397z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Mic = () => {
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="18"
    height="18"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M21 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.49a1 1 0 0 1 .386-.79l8-6.222a1 1 0 0 1 1.228 0l8 6.222a1 1 0 0 1 .386.79V20zm-2-1V9.978l-7-5.444-7 5.444V19h14z" />
  </svg>;
};

export function StartRecording({
  size = 18, // or any default size of your choice
  color = "black", // or any color of your choice
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 470 470"
      fill={color}
    >
      {/* <path fill="none" d="M1084-650v1684H-700V-650h1784m8-8H-708v1700h1800V-658z" />
      <path d="M52 75.9V86h14c1.1 0 2 .9 2 2s-.9 2-2 2H34c-1.1 0-2-.9-2-2s.9-2 2-2h14V75.9c-13.4-1-24-12.3-24-25.9 0-1.1.9-2 2-2s2 .9 2 2c0 12.1 9.9 22 22 22s22-9.9 22-22c0-1.1.9-2 2-2s2 .9 2 2c0 13.7-10.6 24.9-24 25.9zM65 25v25c0 8.3-6.7 15-15 15s-15-6.7-15-15V25c0-8.3 6.7-15 15-15s15 6.7 15 15zm-4 0c0-6.1-4.9-11-11-11s-11 4.9-11 11v25c0 6.1 4.9 11 11 11s11-4.9 11-11V25z" />
    */}

      <path
        d="M235,302.296c47.177,0,85.423-38.245,85.423-85.423V85.423C320.423,38.245,282.177,0,235,0s-85.423,38.245-85.423,85.423
		v131.451C149.577,264.051,187.823,302.296,235,302.296z"
      />
      <path
        d="M350.423,136.148v30h15v50.726c0,71.915-58.508,130.423-130.423,130.423s-130.423-58.507-130.423-130.423v-50.726h15v-30
		h-45v80.726C74.577,300.273,138.551,369,220,376.589V440h-90.444v30h210.889v-30H250v-63.411
		c81.449-7.589,145.423-76.317,145.423-159.716v-80.726H350.423z"
      />
    </svg>
  );
}

export function Speaker({
  size = 18, // or any default size of your choice
  color = "black", // or any color of your choice
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 122.88 55.86"
      fill={color}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M11.32,19.85H33.89L52.56,1a3.55,3.55,0,0,1,5,0,3.48,3.48,0,0,1,1,2.51h0V81.3a3.56,3.56,0,0,1-6.1,2.49l-18.45-15H11.32A11.35,11.35,0,0,1,0,57.49V31.17A11.37,11.37,0,0,1,11.32,19.85ZM74.71,31.62A3.32,3.32,0,0,1,81,29.51c1.14,3.39,1.69,8.66,1.6,13.67s-.81,9.72-2.19,12.57a3.33,3.33,0,0,1-6-2.91c1-2,1.47-5.76,1.55-9.77a38.19,38.19,0,0,0-1.27-11.45Zm17.14-12.4A3.32,3.32,0,0,1,98,16.67c3.08,7.4,4.75,16.71,4.89,26s-1.21,18.25-4.14,25.51a3.31,3.31,0,0,1-6.15-2.47c2.6-6.44,3.79-14.67,3.67-23s-1.63-16.86-4.41-23.5ZM108.42,8.68a3.32,3.32,0,1,1,6-2.88,89.44,89.44,0,0,1,8.48,37.53c.1,12.58-2.44,25.12-8,35.81a3.31,3.31,0,1,1-5.89-3c5-9.71,7.32-21.17,7.23-32.72a82.47,82.47,0,0,0-7.83-34.7Z" />
    </svg>
  );
}

export function StopRecording({
  size = 18, // or any default size of your choice
  color = "black", // or any color of your choice
}) {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 24 24"
    //   width={size} // added size here
    //   height={size} // added size here
    //   fill={color} // added color here
    // >
    //   <path fill="none" d="M0 0h24v24H0z" />
    //   <path d="M21 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.49a1 1 0 0 1 .386-.79l8-6.222a1 1 0 0 1 1.228 0l8 6.222a1 1 0 0 1 .386.79V20zm-2-1V9.978l-7-5.444-7 5.444V19h14z" />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={color}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M6 5h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1z" />
    </svg>
  );
}
