export const TRIAL_BALANCE = 'TRIAL_BALANCE'; 
export const TRIAL_BALANCE_SUCCESS = 'TRIAL_BALANCE_SUCCESS'; 

export const PROFIT_LOSS = 'PROFIT_LOSS'; 
export const PROFIT_LOSS_SUCCESS = 'PROFIT_LOSS_SUCCESS'; 

export const BALANCE_SHEET = 'BALANCE_SHEET'; 
export const BALANCE_SHEET_SUCCESS = 'BALANCE_SHEET_SUCCESS'; 

export const GENERAL_LEDGER = 'GENERAL_LEDGER'; 
export const GENERAL_LEDGER_SUCCESS = 'GENERAL_LEDGER_SUCCESS'; 

export const ACCOUNT_TRANSACTION = 'ACCOUNT_TRANSACTION'; 
export const ACCOUNT_TRANSACTION_SUCCESS = 'ACCOUNT_TRANSACTION_SUCCESS'; 


export const JOURNAL_REPORT = 'JOURNAL_REPORT'; 
export const JOURNAL_REPORT_SUCCESS = 'JOURNAL_REPORT_SUCCESS'; 

export const START_LOADING = 'START_LOADING'; 


export const TAX_LIST = 'TAX_LIST'; 
export const TAX_SEARCH = 'TAX_SEARCH'; 
export const TAX_DELETE = 'TAX_DELETE'; 
export const TAX_LIST_SUCCESS = 'TAX_LIST_SUCCESS'; 
export const TAX_ADD = 'ADD_TAX'; 
export const TAX_ADD_SUCCESS = 'ADD_TAX_SUCCESS'; 

export const EXPENSE_LIST = 'EXPENSE_LIST'; 
export const EXPENSE_SEARCH = 'EXPENSE_SEARCH'; 
export const EXPENSE_DELETE = 'EXPENSE_DELETE'; 
export const EXPENSE_LIST_SUCCESS = 'EXPENSE_LIST_SUCCESS'; 
export const EXPENSE_ADD = 'ADD_EXPENSE'; 
export const EXPENSE_ADD_SUCCESS = 'ADD_EXPENSE_SUCCESS'; 


export const COA_LIST = 'COA_LIST'; 
export const COA_SEARCH = 'COA_SEARCH'; 
export const COA_DELETE = 'COA_DELETE'; 
export const COA_LIST_SUCCESS = 'COA_LIST_SUCCESS'; 
export const COA_ADD = 'COA_ADD'; 
export const COA_ADD_SUCCESS = 'COA_ADD_SUCCESS'; 

export const COA_LIST_EXPENSE = 'COA_LIST_EXPENSE'; 
export const COA_LIST_EXPENSE_SUCCESS = 'COA_LIST_EXPENSE_SUCCESS'; 

export const COA_LIST_BANK = 'COA_LIST_BANK'; 
export const COA_LIST_BANK_SUCCESS = 'COA_LIST_BANK_SUCCESS'; 

export const CONTACT_LIST = 'CONTACT_LIST'; 
export const CONTACT_SEARCH = 'CONTACT_SEARCH'; 
export const CONTACT_DETAILS = 'CONTACT_DETAILS'; 
export const CONTACT_DETAILS_SUCCESS = 'CONTACT_DETAILS_SUCCESS'; 
export const CONTACT_DELETE = 'CONTACT_DELETE'; 
export const CONTACT_LIST_SUCCESS = 'CONTACT_LIST_SUCCESS'; 
export const CONTACT_ADD = 'CONTACT_ADD'; 
export const CONTACT_HIDE_MESSAGE = 'CONTACT_HIDE_MESSAGE'; 
export const CONTACT_ADD_SUCCESS = 'CONTACT_ADD_SUCCESS';

export const QUOTE_LIST = 'QUOTE_LIST'; 
export const QUOTE_SEARCH = 'QUOTE_SEARCH'; 
export const QUOTE_DETAILS = 'QUOTE_DETAILS'; 
export const QUOTE_DETAILS_SUCCESS = 'QUOTE_DETAILS_SUCCESS'; 
export const QUOTE_UPDATE_STATUS = 'QUOTE_UPDATE_STATUS'; 
export const QUOTE_UPDATE_STATUS_SUCCESS = 'QUOTE_UPDATE_STATUS_SUCCESS'; 
export const QUOTE_DELETE = 'QUOTE_DELETE'; 
export const QUOTE_DELETE_SUCCESS = 'QUOTE_DELETE_SUCCESS';
export const QUOTE_LIST_SUCCESS = 'QUOTE_LIST_SUCCESS'; 
export const QUOTE_ADD = 'QUOTE_ADD'; 
export const QUOTE_HIDE_MESSAGE = 'QUOTE_HIDE_MESSAGE'; 
export const QUOTE_ADD_SUCCESS = 'QUOTE_ADD_SUCCESS';
export const QUOTE_CLEAR_DATA = 'QUOTE_CLEAR_DATA';
export const MARK_AS_LOADING = 'MARK_AS_LOADING'; 



export const JOURNAL_LIST = 'JOURNAL_LIST'; 
export const JOURNAL_SEARCH = 'JOURNAL_SEARCH'; 
export const JOURNAL_DETAILS = 'JOURNAL_DETAILS'; 
export const JOURNAL_DETAILS_SUCCESS = 'JOURNAL_DETAILS_SUCCESS'; 
export const JOURNAL_UPDATE_STATUS = 'JOURNAL_UPDATE_STATUS'; 
export const JOURNAL_UPDATE_STATUS_SUCCESS = 'JOURNAL_UPDATE_STATUS_SUCCESS'; 
export const JOURNAL_DELETE = 'JOURNAL_DELETE'; 
export const JOURNAL_DELETE_SUCCESS = 'JOURNAL_DELETE_SUCCESS';
export const JOURNAL_LIST_SUCCESS = 'JOURNAL_LIST_SUCCESS'; 
export const JOURNAL_ADD = 'JOURNAL_ADD'; 
export const JOURNAL_HIDE_MESSAGE = 'JOURNAL_HIDE_MESSAGE'; 
export const JOURNAL_ADD_SUCCESS = 'JOURNAL_ADD_SUCCESS';
export const JOURNAL_CLEAR_DATA = 'JOURNAL_CLEAR_DATA';


export const INVOICE_LIST = 'INVOICE_LIST'; 
export const INVOICE_SEARCH = 'INVOICE_SEARCH'; 
export const INVOICE_DETAILS = 'INVOICE_DETAILS'; 
export const INVOICE_DETAILS_SUCCESS = 'INVOICE_DETAILS_SUCCESS'; 
export const INVOICE_UPDATE_STATUS = 'INVOICE_UPDATE_STATUS'; 
export const INVOICE_UPDATE_STATUS_SUCCESS = 'INVOICE_UPDATE_STATUS_SUCCESS'; 
export const INVOICE_LIST_SUCCESS = 'INVOICE_LIST_SUCCESS'; 
export const INVOICE_ADD = 'INVOICE_ADD'; 
export const INVOICE_HIDE_MESSAGE = 'INVOICE_HIDE_MESSAGE'; 
export const INVOICE_ADD_SUCCESS = 'INVOICE_ADD_SUCCESS';
export const INVOICE_CLEAR_DATA = 'INVOICE_CLEAR_DATA';

export const INVOICE_PAYMENT = 'INVOICE_PAYMENT';
export const INVOICE_PAYMENT_SUCCESS = 'INVOICE_PAYMENT_SUCCESS';
export const INVOICE_PAYMENT_ERROR = 'INVOICE_PAYMENT_ERROR';

export const BILL_LIST = 'BILL_LIST'; 
export const BILL_SEARCH = 'BILL_SEARCH'; 
export const BILL_DETAILS = 'BILL_DETAILS'; 
export const BILL_DETAILS_SUCCESS = 'BILL_DETAILS_SUCCESS'; 
export const BILL_UPDATE_STATUS = 'BILL_UPDATE_STATUS'; 
export const BILL_UPDATE_STATUS_SUCCESS = 'BILL_UPDATE_STATUS_SUCCESS'; 
export const BILL_LIST_SUCCESS = 'BILL_LIST_SUCCESS'; 
export const BILL_ADD = 'BILL_ADD'; 
export const BILL_DELETE = 'BILL_DELETE'; 
export const BILL_DELETE_SUCCESS = 'BILL_DELETE_SUCCESS';
export const BILL_HIDE_MESSAGE = 'BILL_HIDE_MESSAGE'; 
export const BILL_ADD_SUCCESS = 'BILL_ADD_SUCCESS';
export const BILL_CLEAR_DATA = 'BILL_CLEAR_DATA';

export const BILL_PAYMENT = 'BILL_PAYMENT';
export const BILL_PAYMENT_SUCCESS = 'BILL_PAYMENT_SUCCESS';
export const BILL_PAYMENT_ERROR = 'BILL_PAYMENT_ERROR';


export const PURCHASE_LIST = 'PURCHASE_LIST'; 
export const PURCHASE_SEARCH = 'PURCHASE_SEARCH'; 
export const PURCHASE_DETAILS = 'PURCHASE_DETAILS'; 
export const PURCHASE_DETAILS_SUCCESS = 'PURCHASE_DETAILS_SUCCESS'; 
export const PURCHASE_UPDATE_STATUS = 'PURCHASE_UPDATE_STATUS'; 
export const PURCHASE_UPDATE_STATUS_SUCCESS = 'PURCHASE_UPDATE_STATUS_SUCCESS'; 
export const PURCHASE_DELETE = 'PURCHASE_DELETE'; 
export const PURCHASE_DELETE_SUCCESS = 'PURCHASE_DELETE_SUCCESS';
export const PURCHASE_LIST_SUCCESS = 'PURCHASE_LIST_SUCCESS'; 
export const PURCHASE_ADD = 'PURCHASE_ADD'; 
export const PURCHASE_HIDE_MESSAGE = 'PURCHASE_HIDE_MESSAGE'; 
export const PURCHASE_ADD_SUCCESS = 'PURCHASE_ADD_SUCCESS';
export const PURCHASE_CLEAR_DATA = 'PURCHASE_CLEAR_DATA';



export const BANK_LIST = 'BANK_LIST';  
export const BANK_LIST_SUCCESS = 'BANK_LIST_SUCCESS'; 


export const SHOW_APP_MESSAGE = 'SHOW_APP_MESSAGE';
export const HIDE_APP_MESSAGE = 'HIDE_APP_MESSAGE;'
export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING';

export const INVOICE_NUMBER = 'INVOICE_NUMBER'; 
export const INVOICE_NUMBER_SUCCESS = 'INVOICE_NUMBER_SUCCESS'; 
export const INVOICE_NUMBER_ERROR = 'INVOICE_NUMBER_ERROR'; 

export const PURCHASE_NUMBER = 'PURCHASE_NUMBER'; 
export const PURCHASE_NUMBER_SUCCESS = 'PURCHASE_NUMBER_SUCCESS'; 
export const PURCHASE_NUMBER_ERROR = 'PURCHASE_NUMBER_ERROR'; 

export const QUOTE_NUMBER = 'QUOTE_NUMBER'; 
export const QUOTE_NUMBER_SUCCESS = 'QUOTE_NUMBER_SUCCESS'; 
export const QUOTE_NUMBER_ERROR = 'QUOTE_NUMBER_ERROR'; 

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_LOADING = 'SEND_EMAIL_LOADING';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';

export const COMPANY_DETAILS = 'COMPANY_DETAILS'; 
export const COMPANY_DETAILS_UPDATE = 'COMPANY_DETAILS_UPDATE'; 
export const COMPANY_HIDE_MESSAGE = 'COMPANY_HIDE_MESSAGE'; 
export const COMPANY_DETAILS_UPDATE_SUCCESS = 'COMPANY_DETAILS_UPDATE_SUCCESS'; 
export const COMPANY_DETAILS_UPDATE_ERROR = 'COMPANY_DETAILS_UPDATE_ERROR'; 
export const COMPANY_DETAILS_SUCCESS = 'COMPANY_DETAILS_SUCCESS'; 
export const COMPANY_DETAILS_ERROR = 'COMPANY_DETAILS_ERROR'; 

export const DOWNLOAD_LOADING = 'DOWNLOAD_LOADING';

export const DASHBOARD_CASH_IN_OUT = 'DASHBOARD_CASH_IN_OUT';
export const DASHBOARD_CASH_IN_OUT_SUCCESS = 'DASHBOARD_CASH_IN_OUT_SUCCESS';
export const DASHBOARD_CASH_IN_OUT_ERROR = 'DASHBOARD_CASH_IN_OUT_ERROR';

export const DASHBOARD_DATA = 'DASHBOARD_DATA';
export const DASHBOARD_DATA_SUCCESS = 'DASHBOARD_DATA_SUCCESS';
export const DASHBOARD_DATA_ERROR = 'DASHBOARD_DATA_ERROR';

export const DASHBOARD_CHART_DATA = 'DASHBOARD_CHART_DATA';
export const DASHBOARD_CHART_DATA_SUCCESS = 'DASHBOARD_CHART_DATA_SUCCESS';
export const DASHBOARD_CHART_DATA_ERROR = 'DASHBOARD_CHART_DATA_ERROR';

