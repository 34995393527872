import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Inbox from './Inbox';
import Business from './Business';


export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Business(),

  ]);
}
