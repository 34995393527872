import React, { useEffect, useState } from "react";
import axios from "axios";
import { message, Button } from "antd";
import { MSG_DURATION } from "constants/AppConstant";
import { useLocation } from "react-router-dom";
import { API_BASE_URL } from "configs/AppConfig";
import authApi from "api/auth";

const ValidateCustomerTriggerReport = () => {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  console.log("Report page opened");
  const validateCustomer = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const accessKey = "testKey";
      const email = "test@gmail.com";
      const fullName = "Luka+Doncic";

      const url = `${API_BASE_URL}/auth/validateCustomer?access_key=${accessKey}&email=${email}&full_name=${fullName}`;

      const response = await axios.post(url);
      localStorage.setItem("auth_token", response.data.access_token);

      await authApi.getMe();
      window.location.href = "/app/dashboards/assessment";
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Error occurred. Please contact your administrator.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        <Button
          style={{
            marginBottom: "20px",
            cursor: "pointer",
            border: "1px solid black",
            borderRadius: "5px",
            padding: "8px 16px",
            backgroundColor: "transparent",
            color: "black",
          }}
          onClick={validateCustomer}
        >
          Get assessment report
        </Button>
        {errorMessage && <div>{errorMessage}</div>}
      </div>
    </div>
  );
};

export default ValidateCustomerTriggerReport;
