import {
    SET_USER,
} from "../constants/Auth";

const initState = {
    loading: false,
    message: "",
    showMessage: false,
    userData: {
        "full_name": "speakho User",
        "email": "",
        "is_active": false,
        "is_superuser": false,
        "subscription_plan": {
            "id": 0,
            "plan_name": "",
            "amount": 0,
            "audio_duration": 0
        },
    },
   
};

const user = (state = initState, action) => {
    switch (action.type) {
        case SET_USER:
            console.log('action',action);
            return {
                ...state,
                loading: false,
                user: action.payload,
            };
     
        default:
            return state;
    }
};

export default user;
