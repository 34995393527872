import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
let token = "";
if (window !== undefined && window.localStorage !== undefined) {
  token = window.localStorage.getItem("auth_token");
}
const config = {
  headers: {
    Authorization: "Bearer " + token,
  },
};

const multipartConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
    // Authorization: "Bearer " + token,
  },
};
class AuthApi {
  login(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let formData = new FormData();
        formData.append("username", payload.username);
        formData.append("password", payload.password);
        const { data } = await axios.post(
          API_BASE_URL + "/auth/token",
          formData,
          multipartConfig
        );

          localStorage.setItem("auth_token", data.access_token);
          resolve({ success: true });
        
      } catch (error) {
        reject(new Error(error));
      }
    });
  }

  register(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await axios.post(
          API_BASE_URL + "/auth/signup",
          payload
        );
        console.log('data a', data);
        if (data.success === "true") {
          resolve(data);
        }
      } catch (error) {
        reject(new Error(error));
      }
    });
  }

  logout() {
    return new Promise(async (resolve, reject) => {
      try {
        token.length > 0 && localStorage.removeItem("auth_token");
        resolve({ success: true });
      } catch (error) {
        reject(new Error(error));
      }
    });
  }

  getMe() {
    return new Promise(async (resolve, reject) => {
      try {
        let token = "";
        if (window !== undefined && window.localStorage !== undefined) {
          token = window.localStorage.getItem("auth_token");
        }
        // const { data } = await axios.get(API_BASE_URL + "/users/me", config);
        const { data } = await axios.post(API_BASE_URL + "/user/me", {}, {
          headers: {
            // Authorization: "Bearer " + localStorage.getItem("auth_token"),
                Authorization: "Bearer " + token,
          
          },
        });

        // Once user is logged in, we are setting default key.
        localStorage.setItem("customer_key", "testkey");
        // const {data} = await fetch({
        //   url: "/users/me",
        //   method: "get",
        //   data: {},
        // });
        // console.log('data', data);
        resolve(data);
      } catch (error) {
        if (error.response) {
          const { status } = error.response
          console.log('data', error.response);
          if (status == 403) {
            window.location.href = "/auth/login";
          }

        }
        reject(new Error(error));
      }
    });
  }
}
const authApi = new AuthApi();
export default authApi;
