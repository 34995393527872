import fetch from "auth/FetchInterceptor";
import axios from "axios";

const JwtAuthService = {};

JwtAuthService.login = function (data) {
  const formData = new FormData();
  formData.append("username", data.email);
  formData.append("password", data.password);
  return fetch({
    url: "/auth/login",
    method: "post",
    headers: {
      "public-request": "true",
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};

JwtAuthService.logout = function (data) {
  return fetch({
    url: "/auth/logout",
    method: "post",
    data: data,
  });
};

JwtAuthService.signUp = function (data) {
  console.log("🚀 ~ file: JwtAuthService.js ~ line 30 ~ data", data);
  return fetch({
    url: "/users/create",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

export default JwtAuthService;
